// react
import React, { useMemo } from 'react'
// next
import Head from 'next/head'
import dynamic from 'next/dynamic'
// contexts
import { CollapseDrawerProvider } from '~/contexts/CollapseDrawerContext'
// theme
import ThemeProvider from '~/theme'
// components
import ProgressBar from '~/components/ProgressBar'
import NotistackProvider from '~/components/NotistackProvider'
// mui
import { Box, CircularProgress } from '@mui/material'
// emotion
import { CacheProvider } from '@emotion/react'
import createEmotionCache from '~/libs/createEmotionCache'
// swr
import { SWRConfig } from 'swr'
// auth
import { FirebaseAuthProvider } from '~/contexts/FirebaseAuthContext'
// error handling
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
// firebase
import { initializeApp } from 'firebase/app'
// luxon
import { Settings } from 'luxon'
// config
import { FIREBASE_API, BUGSNAG_API_KEY, BUGSNAG_RELEASE_STAGE } from '~/config'
// i18n
import { useLinguiInit } from '~/i18n/utils'
import { i18n } from '@lingui/core'
import { I18nProvider } from '@lingui/react'

// ----------------------------------------------------------------------

// luxon config
Settings.defaultZone = 'Asia/Jakarta'
Settings.defaultLocale = 'en-ID'

// bugsnag
Bugsnag.start({
  apiKey: BUGSNAG_API_KEY,
  plugins: [new BugsnagPluginReact()],
  releaseStage: BUGSNAG_RELEASE_STAGE ?? 'development',
  enabledReleaseStages: ['production', 'staging']
})

// ----------------------------------------------------------------------

const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React)

// lazy load error overlay
const ErrorOverlay = dynamic(() => import('~/components/ErrorOverlay'), {
  ssr: false,
  loading: () => (
    <Box
      sx={{
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        position: 'fixed',
        zIndex: 'overlay',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'background.paper'
      }}
    >
      <CircularProgress />
    </Box>
  )
})

// emotion cache for SSR
const clientSideEmotionCache = createEmotionCache()

// SWR Config
const SWR_CONFIG = {
  refreshInterval: 0,
  shouldRetryOnError: false,
  revalidateOnFocus: false
}

// ----------------------------------------------------------------------

/**
 * @param {Object} props
 * @param {Object} props.Component
 * @param {Object} props.emotionCache
 * @param {Object} props.pageProps
 * @returns {JSX.Element}
 */
export default function ClickyApp({
  Component,
  emotionCache = clientSideEmotionCache,
  pageProps
}) {
  const getLayout = Component.getLayout ?? ((page) => page)

  // initialize firebase
  // ----------------------------------------------------------------------
  const firebaseApp = useMemo(() => {
    return initializeApp(FIREBASE_API)
  }, [])

  // initialzie lingui
  // ----------------------------------------------------------------------
  useLinguiInit(pageProps.i18n)

  return (
    <>
      <Head>
        <meta
          name='viewport'
          content='initial-scale=1, width=device-width'
        />
      </Head>

      <CacheProvider value={emotionCache}>
        <SWRConfig value={SWR_CONFIG}>
          <ThemeProvider>
            <NotistackProvider>
              <ErrorBoundary FallbackComponent={ErrorOverlay}>
                <FirebaseAuthProvider firebaseApp={firebaseApp}>
                  <CollapseDrawerProvider>
                    <ProgressBar />

                    <I18nProvider i18n={i18n}>
                      {getLayout(<Component {...pageProps} />)}
                    </I18nProvider>
                  </CollapseDrawerProvider>
                </FirebaseAuthProvider>
              </ErrorBoundary>
            </NotistackProvider>
          </ThemeProvider>
        </SWRConfig>
      </CacheProvider>
    </>
  )
}

// ----------------------------------------------------------------------
